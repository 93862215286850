const MoonIcon = ({ ...props }) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_38_1724)">
			<path
				className="[fillRule:evenodd] group-hover:[fillRule:nonzero]"
				clipRule="evenodd"
				d="M12.1538 1.74845C12.6724 1.75789 12.7501 2.49664 12.306 2.76465C9.51898 4.44681 8.05638 7.82474 8.94114 11.1267C10.0132 15.1277 14.1257 17.5021 18.1267 16.43C18.8349 16.2403 19.492 15.9553 20.0882 15.5925C20.5318 15.3226 21.1533 15.7352 20.922 16.2001C19.6891 18.6783 17.4391 20.6358 14.5552 21.4085C9.22052 22.838 3.73715 19.6721 2.30773 14.3375C0.878316 9.00282 4.04414 3.51945 9.3788 2.09003C10.3067 1.84141 11.239 1.73181 12.1538 1.74845Z"
				fill="currentColor"
			/>
		</g>
		<defs>
			<clipPath id="clip0_38_1724">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
)

export default MoonIcon
