export const avatarOptions: string[] = [
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811676/avatars/default/1black_bvnsov.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811677/avatars/default/1blue_gehuyd.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811677/avatars/default/1red_rrams5.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811678/avatars/default/1white_mekt6d.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811679/avatars/default/2black_s4lww0.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811680/avatars/default/2blue_gmybp1.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811681/avatars/default/2red_legsji.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811682/avatars/default/2white_drnr3v.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811683/avatars/default/3black_qawiwo.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811684/avatars/default/3blue_cporiy.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811686/avatars/default/3red_nihmha.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811686/avatars/default/3white_uuu4m1.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811688/avatars/default/4black_uvyaoo.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811689/avatars/default/4blue_nclwej.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811691/avatars/default/4red_tjuyrj.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811692/avatars/default/4white_mvyqn2.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811693/avatars/default/5black_sdwmcq.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811694/avatars/default/5blue_q6e4yl.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811697/avatars/default/5red_bdnyza.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811698/avatars/default/5white_uhhedi.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811700/avatars/default/6black_yh1qdo.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811701/avatars/default/6blue_mcfrdl.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811704/avatars/default/6red_hdsebp.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811705/avatars/default/6white_jejaix.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811708/avatars/default/7black_lyyn9i.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811708/avatars/default/7blue_yinhut.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811711/avatars/default/7red_yasovf.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811712/avatars/default/7white_hnftsv.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811715/avatars/default/8black_aau51w.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811715/avatars/default/8blue_zw1kn1.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811718/avatars/default/8red_r1jkyh.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811719/avatars/default/8white_qgtz4t.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811722/avatars/default/9black_cdyflj.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811722/avatars/default/9blue_q0dqku.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811725/avatars/default/9red_zhs2of.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811727/avatars/default/9white_dcmlop.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811728/avatars/default/10black_mw6nwu.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811728/avatars/default/10blue_qxmqdr.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811731/avatars/default/10red_yzzudo.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811732/avatars/default/10white_mmmzkc.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811735/avatars/default/11black_ucivdb.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811736/avatars/default/11blue_frfd1w.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811739/avatars/default/11red_gr2jiw.png',
	'https://res.cloudinary.com/dcq1cn4n8/image/upload/v1727811739/avatars/default/11white_btg56i.png',
]

export const getRandomAvatar = () => {
	const randomIndex = Math.floor(Math.random() * avatarOptions.length)
	const randomAvatar = avatarOptions[randomIndex]
	return randomAvatar
}
