const BlackFillHorizontalGlyph = ({ ...props }) => {
	return (
		<svg
			{...props}
			width="64"
			height="40"
			viewBox="0 0 64 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_1_20)">
				<rect
					width="60"
					height="30.2307"
					transform="translate(2 4.88464)"
					fill="black"
				/>
				<path
					d="M42.2508 14.1214H50.797V15.7873H43.9786V18.7815H50.797V20.4567H43.9786V25.1571H42.2508V14.1214ZM3.85478 6.71465H30.7599V33.2729H3.85478V6.71465ZM2 35.1153H62V4.88464H2V35.1153Z"
					fill="white"
				/>
				<mask
					id="mask0_1_20"
					className={'[mask-type:luminance]'}
					maskUnits="userSpaceOnUse"
					x="2"
					y="4"
					width="60"
					height="32"
				>
					<path d="M62 4.88464H2V35.1153H62V4.88464Z" fill="white" />
				</mask>
				<g mask="url(#mask0_1_20)">
					<path
						d="M14.6583 20.1564L16.3335 16.0381C16.3738 15.9019 16.4481 15.7997 16.5224 15.7563C16.6896 15.6418 16.9869 15.651 17.1448 15.747C17.2191 15.7873 17.281 15.8709 17.3244 16.0071L19.0089 20.1564H14.6552H14.6583ZM18.981 15.4962C18.7487 14.9451 18.4763 14.5487 18.1697 14.3103C17.8539 14.0595 17.4204 13.9356 16.8723 13.9356C16.3242 13.9356 15.8598 14.0595 15.5439 14.301C15.2281 14.5394 14.9649 14.9234 14.7419 15.456L10.7382 25.1603H12.6147L13.9864 21.7696H19.656L21.0154 25.1603H22.9228L18.981 15.4962Z"
						fill="white"
					/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_1_20">
					<rect
						width="60"
						height="30.2307"
						fill="white"
						transform="translate(2 4.88464)"
					/>
				</clipPath>
			</defs>
		</svg>
	)
}

export default BlackFillHorizontalGlyph
