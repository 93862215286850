/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
	NavigateFunction,
	useLoaderData,
	useLocation,
	useNavigate,
	useSearchParams,
} from '@remix-run/react'
import PageTemplate from '../layout/pageTemplate'
import {
	useDynamicContext,
	useIsLoggedIn,
	DynamicUserProfile,
	DynamicContext,
} from '@dynamic-labs/sdk-react-core'
import { loader } from '#app/root.tsx'
// import { useOptionalUser } from '#app/utils/user.ts'
import { Theme, useTheme } from '#app/utils/providers/themeProvider'
import FallbackAvatar from '#images/avatar11white.png'
import { useEffect } from 'react'
import useAnalytics from '#app/utils/analytics/useAnalytics'

export default function AppWithLayout({
	children,
	theme = Theme.LIGHT,
	referrerPath,
	env,
}: {
	children: React.ReactNode
	theme?: Theme
	referrerPath: string
	env: {
		GA_TRACKING_ID: string
	}
}) {
	const data = useLoaderData<typeof loader>()
	// const serverUser = useOptionalUser()
	const navigate = useNavigate()
	const location = useLocation()
	const [searchParams, setSearchParams] = useSearchParams()
	const [, setTheme] = useTheme()
	const { pageViewed } = useAnalytics()

	const themeUpdateMethod = () => {
		setTheme(prevTheme =>
			prevTheme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT,
		)
	}

	useEffect(() => {
		pageViewed(location.pathname)
	}, [location.pathname])

	// TODO: find a better way to handle Dynamic only loading on the client
	// since provider is not available on the server
	// Maybe we can load this in entry.client.server?
	// @ts-ignore
	if (!DynamicContext._currentValue) {
		return (
			// @ts-ignore
			<PageTemplate
				user={'loading'}
				menuOptions={[
					{
						title: 'Edit Profile',
						// description: "View your signed messages",
						action: () => {
							if (location.pathname === '/userProfile') {
								setSearchParams(prev => {
									prev.set('view', 'edit')
									return prev
								})
							} else {
								navigate('/userProfile?view=edit')
							}
						},
					},
					{
						title: 'Signatures',
						// description: "View your signed messages",
						action: () => {
							if (location.pathname === '/userProfile') {
								setSearchParams(prev => {
									prev.set('view', 'signatures')
									return prev
								})
							} else {
								navigate('/userProfile?view=signatures')
							}
						},
					},
				]}
				theme={theme}
				updateTheme={() => themeUpdateMethod()}
				onLogin={() => {}}
				onLogout={() => {}}
				onUserProfile={() =>
					navigate('/userProfile', {
						unstable_viewTransition: true,
					})
				}
				logoLink={data.requestInfo.origin}
			>
				{children}
			</PageTemplate>
		)
	} else {
		return (
			<DynamicAppLayout
				children={children}
				data={data}
				theme={theme}
				navigate={navigate}
				themeUpdateMethod={themeUpdateMethod}
			/>
		)
	}
}

const DynamicAppLayout = ({
	children,
	data,
	theme,
	navigate,
	themeUpdateMethod,
}: {
	children: React.ReactNode
	data: any
	theme: 'light' | 'dark'
	navigate: NavigateFunction
	themeUpdateMethod: () => void
}) => {
	const {
		user,
		primaryWallet,
		handleLogOut,
		setShowAuthFlow,
		setShowDynamicUserProfile,
	} = useDynamicContext()
	const [searchParams, setSearchParams] = useSearchParams()
	const location = useLocation()
	const isAuthenticated = useIsLoggedIn()

	const handleLogin = () => {
		setShowAuthFlow(true)
	}
	const handleLogout = () => {
		handleLogOut()
	}
	const handleUserProfile = () => {
		setShowDynamicUserProfile(true)
	}

	return (
		<PageTemplate
			user={
				user
					? {
							name: user.username || '',
							id: user.userId || '',
							wallet: primaryWallet ? primaryWallet.address : undefined,
							image:
								user.metadata && user.metadata?.avatar
									? user.metadata?.avatar
									: FallbackAvatar,
						}
					: isAuthenticated === false
						? undefined
						: 'loading'
			}
			menuOptions={[
				{
					title: 'Edit Profile',
					// description: "View your signed messages",
					action: () => {
						navigate('/userProfile?view=edit')
					},
				},
				{
					title: 'Signatures',
					// description: "View your signed messages",
					action: () => {
						navigate('/userProfile?view=signatures')
					},
				},
			]}
			theme={theme}
			updateTheme={() => themeUpdateMethod()}
			onLogin={() => handleLogin()}
			onLogout={() => handleLogout()}
			onUserProfile={() => handleUserProfile()}
			logoLink={data.requestInfo.origin}
		>
			<DynamicUserProfile />
			{children}
		</PageTemplate>
	)
}
