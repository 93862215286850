import { Switch, Field, Label } from '@headlessui/react'
import clsx from 'clsx'
import { MoonIcon, SunIcon } from '../icons'

const ThemeButton = ({
	theme = 'light',
	updateTheme,
	...props
}: {
	theme: 'light' | 'dark'
	updateTheme: () => void
}) => {
	return (
		<>
			<button
				className={clsx(
					'group text-uiTertiaryDark active:text-textPrimaryLight dark:text-white active:dark:text-textSecondaryDark',
					'hidden h-6 w-6 items-center justify-center rounded-full outline-0 ring-0 sm:flex',
				)}
				{...props}
				onClick={() => updateTheme()}
			>
				<div className={'flex sm:visible'}>
					{theme === 'light' && <SunIcon />}
					{theme === 'dark' && <MoonIcon />}
				</div>
			</button>
			<Field as="div" className="flex items-center sm:hidden">
				<Label
					as="div"
					className={clsx('mr-2', theme === 'dark' && 'text-light')}
				>
					{theme === 'light' && <SunIcon />}
					{theme === 'dark' && <MoonIcon />}
				</Label>
				<Switch
					checked={theme === 'dark'}
					onChange={() => updateTheme()}
					className={clsx(
						theme === 'dark'
							? 'bg-textPrimaryLight dark:bg-textPrimaryDark'
							: 'bg-textInactiveLight dark:bg-uiSecondaryDark',
						'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
					)}
				>
					<span
						aria-hidden="true"
						className={clsx(
							theme === 'dark'
								? 'translate-x-5 bg-white dark:bg-uiSecondaryDark'
								: 'translate-x-0 bg-white dark:bg-textInactiveDark',
							'pointer-events-none inline-block h-5 w-5 transform rounded-full shadow ring-0 transition duration-200 ease-in-out',
						)}
					/>
				</Switch>
			</Field>
		</>
	)
}

export default ThemeButton
