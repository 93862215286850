import { getRandomAvatar } from '#app/utils/avatars'
import { EthereumWalletConnectors } from '@dynamic-labs/ethereum'
import { DynamicContextProvider } from '@dynamic-labs/sdk-react-core'
import { SolanaWalletConnectors } from '@dynamic-labs/solana'
import { useFetcher, useRevalidator } from '@remix-run/react'
import { ClientOnly } from 'remix-utils/client-only'
import useAnalytics from '#app/utils/analytics/useAnalytics.ts'
// import { updateDynamicUser } from '#app/utils/dynamic.client.js'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Web3Provider = ({
	children,
	dynamicId,
	theme = 'light',
}: {
	children: React.ReactNode
	dynamicId: string
	theme: 'light' | 'dark'
}) => {
	const dynamicIdInput = dynamicId || 'sandbox'
	const revalidator = useRevalidator()
	const { identifyUser } = useAnalytics()
	const fetcher = useFetcher()
	return (
		<ClientOnly fallback={children}>
			{() => (
				<DynamicContextProvider
					theme={theme}
					settings={{
						// Find your environment id at https://app.dynamic.xyz/dashboard/developer
						appName: import.meta.env.VITE_APP_NAME,
						environmentId: dynamicIdInput,
						mobileExperience: 'redirect',
						walletConnectors: [
							EthereumWalletConnectors,
							SolanaWalletConnectors,
						],
						eventsCallbacks: {
							onAuthSuccess: async args => {
								const formData = new FormData()
								formData.append('authToken', args.authToken)
								args.user.userId && formData.append('userId', args.user.userId)
								args.user.username &&
									formData.append('userName', args.user.username)
								args.user.sessionId &&
									formData.append('sessionId', args.user.sessionId)
								args.primaryWallet &&
									args.primaryWallet.address &&
									formData.append('address', args.primaryWallet.address)
								console.log('AUTH ARGS', args)
								args.user.newUser !== undefined &&
									formData.append('newUser', String(args.user.newUser))
								if (args.user.newUser) {
									const avatar = getRandomAvatar()
									args.user.metadata = { avatar: avatar }
									formData.set('avatar', avatar)
								} else if (
									args.user.metadata &&
									!args.user.metadata.hasOwnProperty('avatar') &&
									args.user.userId
								) {
									console.log('HAS NO AVATAR ON OLD USER')
									const avatar = getRandomAvatar()
									args.user.metadata = { avatar: avatar }
									formData.set('avatar', avatar)
								}
								fetch('/login', {
									method: 'POST',
									body: formData,
								})
									.then(() => {
										args.user.userId &&
											args.user.username &&
											identifyUser(
												args.user.userId,
												args.user.username,
												args.user.email || '',
												args.user.newUser,
											)
										if (
											window.location.pathname &&
											window.location.pathname
												.toLowerCase()
												.includes('userprofile')
										) {
											window.location.href = '/'
										}
									})
									.catch(e => {
										console.error('LOGOUT ERROR', e)
									})
							},
							onUserProfileUpdate: () => {
								revalidator.revalidate()
							},
							onSignedMessage: ({ messageToSign, signedMessage }) => {
								console.log(
									`onSignedMessage was called: ${messageToSign}, ${signedMessage}`,
								)
							},
							onLogout: user => {
								fetch('/logout', {
									method: 'POST',
									headers: {
										'Content-Type': 'application/json',
									},
								})
							},
						},
					}}
				>
					{children}
				</DynamicContextProvider>
			)}
		</ClientOnly>
	)
}
