import { useRouteLoaderData, useFetcher } from '@remix-run/react'
import { type loader as rootLoader } from '#app/root.tsx'
import { invariant } from './misc.tsx'
import { useHints } from './client-hints.tsx'
import { z } from 'zod'
import { parseWithZod } from '@conform-to/zod'

const ThemeFormSchema = z.object({
	theme: z.enum(['light', 'dark']),
	// this is useful for progressive enhancement
	redirectTo: z.string().optional(),
})

/**
 * @returns the request info from the root loader
 */
export function useRequestInfo() {
	const data = useRouteLoaderData<typeof rootLoader>('root')
	invariant(data?.requestInfo, 'No requestInfo found in root loader')

	return data.requestInfo
}

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */
export function useOptimisticThemeMode() {
	// const fetchers = useFetchers()
	const themeFetcher = useFetcher({ key: 'themeSwitch' })

	if (themeFetcher && themeFetcher.formData) {
		const submission = parseWithZod(themeFetcher.formData, {
			schema: ThemeFormSchema,
		})

		if (submission.status === 'success') {
			return submission.value.theme
		}
	}
}

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
export function useTheme() {
	const hints = useHints()
	const requestInfo = useRequestInfo()
	const optimisticMode = useOptimisticThemeMode()
	if (optimisticMode) {
		return optimisticMode
	}
	const val = requestInfo.userPrefs.theme ?? hints.theme

	return val
}
