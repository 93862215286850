import { type ReactNode } from 'react'
import { AppNavigation, Footer } from '../navigation/index.ts'

const PageTemplate = ({
	user = undefined,
	menuOptions = undefined,
	onLogin = undefined,
	onLogout = undefined,
	onUserProfile = undefined,
	updateTheme = undefined,
	theme = 'light',
	logoLink = undefined,
	children = <></>,
}: {
	user?:
		| { name: string; id: string; wallet?: string; image: string }
		| 'loading'
	menuOptions?: {
		title: string
		description?: string
		action: () => void
		leftIcon?: ReactNode
		rightIcon?: ReactNode
		isVerified?: boolean
		image?: string
	}[]
	onLogin?: () => void
	onLogout?: () => void
	onUserProfile?: () => void
	updateTheme?: () => void
	theme: 'light' | 'dark'
	logoLink?: string
	children: ReactNode
}) => {
	return (
		<div className="flex min-h-screen flex-col bg-white dark:bg-dark">
			<AppNavigation
				user={user}
				menuOptions={menuOptions}
				onLogin={onLogin}
				onLogout={onLogout}
				onUserProfile={onUserProfile}
				updateTheme={updateTheme}
				theme={theme}
				logoLink={logoLink}
			/>
			<main className="flex-grow bg-white dark:bg-dark">{children}</main>
			<Footer />
		</div>
	)
}

export default PageTemplate
