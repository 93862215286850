import React, { useMemo } from 'react'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { AnalyticsContext, AnalyticsContextProps } from './segmentContext'

interface AnalyticsProviderProps {
	children: React.ReactNode
}

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({
	children,
}) => {
	const writeKey = import.meta.env.VITE_SEGMENT_API_KEY ?? ''

	const value: AnalyticsContextProps = useMemo(
		() => ({ analytics: AnalyticsBrowser.load({ writeKey }) }),
		[writeKey],
	)

	return (
		<AnalyticsContext.Provider value={value}>
			{children}
		</AnalyticsContext.Provider>
	)
}
