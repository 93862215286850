const SunIcon = ({ ...props }) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M11 1.5C11 1.22386 11.2239 1 11.5 1H12.5C12.7761 1 13 1.22386 13 1.5V3.5C13 3.77614 12.7761 4 12.5 4H11.5C11.2239 4 11 3.77614 11 3.5V1.5Z"
			fill="currentColor"
		/>
		<path
			className="[fillRule:evenodd] group-hover:[fillRule:nonzero]"
			//   fillRule="evenodd"
			clipRule="evenodd"
			d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z"
			fill="currentColor"
		/>
		<path
			d="M11.5 20C11.2239 20 11 20.2239 11 20.5V22.5C11 22.7761 11.2239 23 11.5 23H12.5C12.7761 23 13 22.7761 13 22.5V20.5C13 20.2239 12.7761 20 12.5 20H11.5Z"
			fill="currentColor"
		/>
		<path
			d="M22.5 11C22.7761 11 23 11.2239 23 11.5V12.5C23 12.7761 22.7761 13 22.5 13H20.5C20.2239 13 20 12.7761 20 12.5V11.5C20 11.2239 20.2239 11 20.5 11H22.5Z"
			fill="currentColor"
		/>
		<path
			d="M4 11.5C4 11.2239 3.77614 11 3.5 11H1.5C1.22386 11 1 11.2239 1 11.5V12.5C1 12.7761 1.22386 13 1.5 13H3.5C3.77614 13 4 12.7761 4 12.5V11.5Z"
			fill="currentColor"
		/>
		<path
			d="M18.7175 3.86827C18.9128 3.67301 19.2294 3.67301 19.4246 3.86827L20.1317 4.57538C20.327 4.77064 20.327 5.08722 20.1317 5.28248L18.7175 6.6967C18.5223 6.89196 18.2057 6.89196 18.0104 6.6967L17.3033 5.98959C17.108 5.79433 17.108 5.47775 17.3033 5.28248L18.7175 3.86827Z"
			fill="currentColor"
		/>
		<path
			d="M5.98959 17.3033C5.79433 17.108 5.47775 17.108 5.28249 17.3033L3.86827 18.7175C3.67301 18.9128 3.67301 19.2294 3.86827 19.4246L4.57538 20.1317C4.77064 20.327 5.08722 20.327 5.28249 20.1317L6.6967 18.7175C6.89196 18.5222 6.89196 18.2057 6.6967 18.0104L5.98959 17.3033Z"
			fill="currentColor"
		/>
		<path
			d="M20.1317 18.7175C20.327 18.9128 20.327 19.2294 20.1317 19.4246L19.4246 20.1317C19.2294 20.327 18.9128 20.327 18.7175 20.1317L17.3033 18.7175C17.108 18.5223 17.108 18.2057 17.3033 18.0104L18.0104 17.3033C18.2057 17.108 18.5223 17.108 18.7175 17.3033L20.1317 18.7175Z"
			fill="currentColor"
		/>
		<path
			d="M6.6967 5.98959C6.89196 5.79433 6.89196 5.47775 6.6967 5.28249L5.28249 3.86827C5.08722 3.67301 4.77064 3.67301 4.57538 3.86827L3.86827 4.57538C3.67301 4.77064 3.67301 5.08722 3.86827 5.28249L5.28249 6.6967C5.47775 6.89196 5.79433 6.89196 5.98959 6.6967L6.6967 5.98959Z"
			fill="currentColor"
		/>
	</svg>
)

export default SunIcon
