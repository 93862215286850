const MenuIcon = ({ ...props }) => {
	return (
		<svg
			width="16"
			height="12"
			viewBox="0 0 16 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.5 2C15.7761 2 16 1.77614 16 1.5V0.5C16 0.223858 15.7761 0 15.5 0H0.5C0.223858 0 0 0.223858 0 0.5V1.5C0 1.77614 0.223858 2 0.5 2H15.5ZM16 6.5C16 6.77614 15.7761 7 15.5 7H0.5C0.223858 7 0 6.77614 0 6.5V5.5C0 5.22386 0.223858 5 0.5 5H15.5C15.7761 5 16 5.22386 16 5.5V6.5ZM16 11.5C16 11.7761 15.7761 12 15.5 12H0.5C0.223858 12 0 11.7761 0 11.5V10.5C0 10.2239 0.223858 10 0.5 10H15.5C15.7761 10 16 10.2239 16 10.5V11.5Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export default MenuIcon
